import * as React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <>
    <div className="flex flex-col items-start px-4 pt-6 space-y-5 border-t border-gray-200 ">
      <h1 className="text-large">
        <span>Oops, page not found</span>
      </h1>
      <Link className="p-4 leading-none text-white bg-black" to="/">
        Go home
      </Link>
    </div>
  </>
);

export default NotFoundPage;
